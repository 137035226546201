/**
 *
 */
const style = (t) => {
    return {
        checkbox: {
            height: '24px',
            '& label': {
                cursor: 'pointer'
            },
            '& .checkbox--icon-right': {
                marginLeft: '5px'
            },
            '& .checkbox--icon-left': {
                marginRight: '5px'
            },
            '& .checkbox--icon-wrapper': {
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center'
            },
            '& input[type="checkbox"]': {
                display: 'none'
            }
        }
    }
}

export default style
