/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useState, useMemo, PropsWithChildren, useContext } from 'react'
import {
    getInvoices,
    getInvoicePdfLink
} from './api_v5'
import { downloadFile } from 'helpers'
import { ControlledError } from 'errors'
import { StatementType } from 'interfaces'

type StatemenetsContextDataType = {
    items: StatementType[],
    getData: () => Promise<void>,
    getPdfBlob: (invoiceId: number) => Promise<Blob>,
    getInvoicePdfLink: (inveoiceId: number) => Promise<void>,
    getFileName: (invoiceId: number) => string,
    statementsTimeFormat: (date: string) => string, // 09/21/2024
    downloadPdf: (id: number) => Promise<string>
}

const StatementsContext = createContext<StatemenetsContextDataType | undefined>(undefined)

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

/**
 *
 */
const StatementsProvider = ({ children }: PropsWithChildren<any>): JSX.Element => {
    const [invoicesApiResponse, setInvoicesApiResponse] = useState(null)

    const items = useMemo(
        () => invoicesApiResponse && invoicesApiResponse.statement_list,
        [invoicesApiResponse]
    )

    const statementsTimeFormat = (str: string) => { // 09/21/2024
        const dateParts = str.split('/')

        return `${months[Number.parseInt(dateParts[0]) - 1]} ${dateParts[1]}, ${dateParts[2]}`
    }

    const getPdfBlob = async (invoiceId: number): Promise<Blob> => {
        const res = await getInvoicePdfLink(invoiceId)
        if (!res?.invoice_link) {
            throw new ControlledError('Error while getting document link')
        }
        const response = await fetch(res.invoice_link)
        const blob = await response.blob()
        return blob
    }

    const getData = async () => {
        if (items) return items
        try {
            const invoices = await getInvoices()
            setInvoicesApiResponse(invoices)
        } catch (err) {
            throw new ControlledError(err)
        }
    }

    const downloadPdf = async (id: number) => {
        const filename = getFileName(id)
        const blob = await getPdfBlob(id)
        downloadFile(blob, filename)

        return filename
    }

    const getFileName = (invoiceId: number) => {
        let fileName
        try {
            const statement = items.find((x) => x.statement_id === invoiceId)
            if (!statement) {
                throw new Error(`No statement found for invoice ID: ${invoiceId}`)
            }
            fileName = `${statementsTimeFormat(statement.billing_period)}.pdf`
        } catch (e) {
            console.error(e)
            fileName = `${invoiceId}.pdf`
        }

        return fileName
    }

    return (
        <StatementsContext.Provider value={{
            getPdfBlob,
            items,
            getData,
            getInvoicePdfLink,
            getFileName,
            statementsTimeFormat,
            downloadPdf

        }}>
            {children}
        </StatementsContext.Provider>
    )
}

/**
 *
 */
const useStatementsContext = () => {
    const statementsData = useContext(StatementsContext)
    if (statementsData === undefined) {
        throw new Error('useStatementsContext must be used inside PaymentMethodProvider tags')
    }

    return { ...statementsData }
}

/**
 *
 */
export { StatementsContext, StatementsProvider, useStatementsContext, StatemenetsContextDataType }
