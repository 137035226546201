import l from 'lang'
import Cachier from 'cachier'
const cachier = new Cachier()
import Session from 'phoenix-api-js-client'

/**
 *
 * @param {object} session phoenix-session
 * @returns
 */
const sessionAccountCacheKey = function (session) {
    return `user-account-${session.user.id}`
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const get_account_information = async function (session, ignore) {
    const ignoreCache = ignore || false
    let account
    if (session.user) {
        if (!session.user.account) {
            const session_cachier = new Cachier(session.user.id)
            if (!ignoreCache && session_cachier.getItem(sessionAccountCacheKey(session))) {
                account = session_cachier.getItem(sessionAccountCacheKey(session))
            } else {
                session_cachier.removeItem(sessionAccountCacheKey(session))
                account = await session.get_item('')
                if (account.default_voip_phone_id) {
                    try {
                        account.default_voip_phone = await session.get_item(
                            `/extensions/${account.default_voip_phone_id}`
                        )
                    } catch (err) {
                        if (err.status === 404) {
                            account.default_voip_phone = null
                        } else {
                            throw new Error(err)
                        }
                    }
                }
                session_cachier.setItem(sessionAccountCacheKey(session), account)
            }
        } else {
            // eslint-disable-next-line prefer-destructuring
            account = session.user.account
        }
        return JSON.parse(JSON.stringify(account))
    }

    return null
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const token_details = async function (session, ignore) {
    const ignore_cache = ignore || false
    if (!session) throw new Error('Session not available')
    let token_info_cache
    const token_cachier = new Cachier(session.user.id)
    const token_info_cache_key = 'token-details'
    if (!ignore_cache && token_cachier.getItem(token_info_cache_key)) {
        token_info_cache = token_cachier.getItem(token_info_cache_key).value
    } else {
        token_cachier.removeItem(token_info_cache_key)
        const response = await session.call_api('get', '/v4/oauth/access-token', null, true)
        token_info_cache = response
        token_cachier.setItem(token_info_cache_key, { value: token_info_cache })
    }
    return token_info_cache
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const nxt_details = async function (session, ignore) {
    const ignore_cache = ignore || false
    if (!session) throw new Error('Session not found')
    let user_nxt_details
    const nxt_cachier = new Cachier(session.user.id)
    const is_user_next_cache_key = 'user-is-nxt'
    if (!ignore_cache && nxt_cachier.getItem(is_user_next_cache_key)) {
        user_nxt_details = nxt_cachier.getItem(is_user_next_cache_key).value
    } else {
        nxt_cachier.removeItem(is_user_next_cache_key)
        const token_info = await token_details(session, ignore_cache)
        user_nxt_details = token_info.nxt_details
        nxt_cachier.setItem(is_user_next_cache_key, { value: user_nxt_details })
    }
    return user_nxt_details
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const nxt_company_inbox_extension = async function (session, ignore) {
    const user_nxt_details = await nxt_details(session, ignore)
    if (user_nxt_details) {
        if (user_nxt_details.company_inbox_extension) return user_nxt_details.company_inbox_extension
        // if (Array.isArray(user_nxt_details.user_extensions) && user_nxt_details.user_extensions.length) {
        //   return user_nxt_details.user_extensions[0];
        // }
    }

    return null
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const nxt_user_extensions = async function (session, ignore) {
    const user_nxt_details = await nxt_details(session, ignore)
    if (user_nxt_details) {
        if (Array.isArray(user_nxt_details.user_extensions) && user_nxt_details.user_extensions.length) {
            return user_nxt_details.user_extensions
        }
    }

    return null
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const is_nxt = async function (session, ignore) {
    const user_nxt_details = await nxt_details(session, ignore)
    return !!user_nxt_details
}

/**
 *
 * @param {object} session phoenix-session
 * @param {boolean} ignore
 * @returns
 */
const is_csr = async function (session, ignore) {
    const token_info = await token_details(session, ignore)
    if (!token_info.scope) return false

    const scopes = token_info.scope.split(' ')
    return !!scopes.find((x) => x.startsWith('csr'))
}

/**
 *
 * @param {object} session phoenix-session
 * @param {string} scope
 * @param {boolean} ignore
 * @returns
 */
const has_scope = async function (session, scope, ignore = false) {
    const token_info = await token_details(session, ignore)
    if (!token_info.scope) return false

    const scopes = token_info.scope.split(' ')
    return !!scopes.find((x) => x.startsWith(scope))
}

const session_main_account_cache_key = 'main_session_account_user_id'

/**
 *
 * @param {object} session phoenix session
 * @param {object} item
 * @param {boolean} subaccounts_owner_mode
 * @returns
 */
const use_subaccount = function (session, item, subaccounts_owner_mode) {
    cachier.setItem(session_main_account_cache_key, session.user)
    cachier.removeItem(sessionAccountCacheKey(session))
    session.user.id = item.id
    if (subaccounts_owner_mode) {
        session.user.subaccounts_owner_is_subaccount = true
    } else {
        session.user.is_subaccount = true
    }
    session.user.account = item.account

    return session
}

/**
 *
 * @param {object} session phoenix session
 * @returns
 */
const use_main_account = function (session) {
    const main_user = cachier.getItem(session_main_account_cache_key)
    if (main_user) {
        session.user = main_user
        return session
    }
    throw new Error('Main account could not be found')
}

/**
 *
 * @param {object} session phoenix session
 * @param {number} ext
 * @returns
 */
const create_default_contact_groups = async (session, ext) => {
    const promise_name = `create_default_groups_${ext}`
    if (window[promise_name]) {
        const groups = await window[promise_name]
        window[promise_name] = null
        try {
            const cache_key = `groups-${ext}`
            const groups_cachier = new Cachier(session.user.id)
            groups_cachier.removeItem(cache_key)
        } catch (err) {
            console.log('Problems handling groups cache', err)
        }

        return groups
    }
    const url = `/extensions/${ext}/contact-groups`
    const groups = ['Friends', 'Family', 'Co-Workers'].map((name) => session.create_item(url, { name }))
    window[promise_name] = Promise.all(groups)

    return create_default_contact_groups(session, ext)
}

/**
 *
 * @param {object} session phoenix session
 * @param {object} address
 * @returns
 */
const validate_address = async function (session, address) {
    if (!address.country) {
        return {
            success: false,
            message: l.t('app.invelid-address', 'Invalid address')
        }
    }
    if (address.country !== 'US') {
        return {
            success: true,
            message: l.t('app.not-us-address', 'Not US address'),
            validated_address: address
        }
    }
    const valid_addresses_cache_key = 'valid-addresses'
    try {
        const address_cachier = new Cachier(session.user.id)
        const valid_addresses = address_cachier.getItem(valid_addresses_cache_key) || []
        let validated_address = { address }
        if (!valid_addresses.includes(JSON.stringify(address).toLowerCase())) {
            validated_address = await session.create_item(
                '/contacts?validation=usps&validation_only=1', { address }
            )
            valid_addresses.push(JSON.stringify(validated_address.address).toLowerCase())
            address_cachier.setItem(valid_addresses_cache_key, [...new Set(valid_addresses)])
        }

        return { success: true, validated_address: validated_address.address }
    } catch (err) {
        let message = 'Something went wrong'
        if (err && err && err['@error'] && err['@error'].fields) {
            message = err['@error'].fields
        }
        return { success: false, message }
    }
}

const PhoenixSessionFactory = (function () {
    function SingletonClass () {
        return new Session({
            client_id: process.env.REACT_APP_CLIENT_ID,
            scope: ['account-owner', 'billing-api'],
            session_name: 'mini-cp',
            id_token_sign_out: true,
            ignore_state: true,
            session_scope: 'browser',
            oauth_api_url: process.env.REACT_APP_OAUTH_API_URL,
            accounts_url: process.env.REACT_APP_ACCOUNTS_URL,
            phoenix_url: process.env.REACT_APP_PHOENIX_URL
        })
    }
    let instance
    return {
        getInstance: () => {
            if (instance == null) {
                instance = new SingletonClass()
                instance.constructor = null
            }
            return instance
        }
    }
})()

/**
 *
 */
const get_phoenix_session = () => {
    return PhoenixSessionFactory.getInstance()
}

/**
 *
 * @param {string} token
 * @returns
 */
const init_phoenix_session = async (token) => {
    const session = get_phoenix_session()
    await session.init_user()
    if (!session.user && token) {
        if (token.startsWith('Bearer ')) token = token.slice(7).trim()
        await session._load_user(`Bearer ${token}`, true)
    }
    if (session?.user?.id && !session.user.account) {
        session.user.account = await get_account_information(session)
    }

    return session
}

/**
 *
 */
const end_phoenix_session = async () => {
    const session = get_phoenix_session()
    if (session.user) await session.sign_out()

    return session
}

/**
 *
 */
export {
    get_account_information,
    token_details,
    nxt_details,
    nxt_company_inbox_extension,
    nxt_user_extensions,
    is_nxt,
    is_csr,
    has_scope,
    use_subaccount,
    use_main_account,
    create_default_contact_groups,
    validate_address,
    get_phoenix_session,
    init_phoenix_session,
    end_phoenix_session
}
